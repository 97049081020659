const store = {
  http: {},
  api: {},
};
let vm;

const read = (s, name) => JSON.parse(JSON.stringify(store[s][name]));

const write = (s, name, o) => {
  store[s][name] = JSON.parse(JSON.stringify({ createdAt: new Date().getTime() / 1000, data: o }));
};

const remove = (s, name) => {
  delete store[s][name];
};

const flush = (s) => {
  const keys = Object.keys(store[s]);
  keys.forEach((k) => delete store[s][k]);
};

// return age of cache in seconds
const age = (s, name) => {
  let createdAt = 0;
  if (store[s][name]) {
    createdAt = store[s][name].createdAt;
  }
  return (new Date().getTime() / 1000) - createdAt;
};

const cache = {
  config(t) {
    vm = t;
  },

  flush() {
    flush('http');
    flush('api');
  },

  http: {
    read(name) {
      return read('http', name);
    },

    write(name, o) {
      write('http', name, o);
    },

    remove(name) {
      remove('http', name);
    },

    flush() {
      flush('http');
    },

    // return age of cache in seconds
    age(name) {
      return age('http', name);
    },

    get(url, config) {
      if (cache.http.age(url) > 180) {
        return new Promise((resolve, reject) => {
          vm.$http.get(url, config)
            .then((res) => {
              cache.http.write(url, res);
              resolve(res);
            })
            .catch((e) => {
              reject(e);
            });
        });
      }
      return new Promise((resolve) => resolve(cache.http.read(url).data));
    },

    update: {
      get(url, config) {
        cache.http.remove(url);
        return cache.http.get(url, config);
      },
    },
  },

  api: {
    read(name) {
      return read('api', name);
    },

    write(name, o) {
      write('api', name, o);
    },

    remove(name) {
      remove('api', name);
    },

    flush() {
      flush('api');
    },

    // return age of cache in seconds
    age(name) {
      return age('api', name);
    },

    get(url, config) {
      if (cache.api.age(url) > 180) {
        return new Promise((resolve, reject) => {
          if (url.startsWith('/projects/') && url.indexOf('%2F') !== -1 && (url.match(/\//g) || []).length === 2) {
            // use /projects and find the project requested in the list
            cache.api.get('/projects', config)
              .then((res) => {
                let p;
                for (let i = 0; i < res.data.length; i += 1) {
                  if (url === `/projects/${res.data[i].group.path}%2F${res.data[i].path}`) {
                    p = res.data[i];
                    break;
                  }
                }
                if (p) {
                  resolve({ data: p });
                } else {
                  reject({ status: 404 }); // eslint-disable-line
                }
              })
              .catch((e) => {
                reject(e);
              });
          } else {
            vm.$api.get(url, config)
              .then((res) => {
                cache.api.write(url, res);
                resolve(res);
              })
              .catch((e) => {
                reject(e);
              });
          }
        });
      }
      return new Promise((resolve) => resolve(cache.api.read(url).data));
    },

    update: {
      get(url, config) {
        cache.api.remove(url);
        return cache.api.get(url, config);
      },
    },
  },
};

export default cache;
