import { format, formatDistance } from 'date-fns';

const datefns = {
  utc: {
    format(d, fmt) {
      return format(this.Date(d), fmt);
    },

    Date(s) {
      let d;
      if (!s) {
        d = new Date();
      } else {
        d = new Date(s);
      }
      return new Date(d.getTime() + d.getTimezoneOffset() * 60000);
    },
  },

  format(d, fmt) {
    return format(new Date(d), fmt);
  },

  formatDistance(s1, s2) {
    const d1 = s1 ? new Date(s1) : new Date();
    const d2 = s2 ? new Date(s2) : new Date();
    let s = formatDistance(d1, d2);
    if (d1 > d2) {
      s += ' ago';
    }
    if (s.startsWith('about ')) {
      return s.substring(6);
    } if (s.startsWith('over ')) {
      return s.substring(5);
    } if (s.startsWith('almost ')) {
      return s.substring(7);
    } if (s.startsWith('less than a minute')) {
      return 'just now';
    }
    return s;
  },

  fromNow(d) {
    return this.formatDistance(undefined, d);
  },

  diff(d1, d2, interval) {
    switch (interval) {
      case 'minutes':
        return Math.floor((d1 - d2) / 1000 / 60);
      case 'days':
        return Math.floor((d1 - d2) / 1000 / 86400);
      case 'months':
        return Math.floor((d1 - d2) / 1000 / 86400 / 30);
      default:
        return Math.floor((d1 - d2) / 1000);
    }
  },

  age(d, interval) {
    return this.diff(new Date(), d, interval);
  },
};

export default datefns;
