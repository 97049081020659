const util = {
  createMetaTag(name, attributes) {
    const t = document.createElement(name);
    Object.keys(attributes).forEach((key) => t.setAttribute(key, attributes[key]));
    t.setAttribute('data-vue-router-controlled', '');
    document.head.appendChild(t);
  },
};

export default util;
