import Vue from 'vue';
import config from '@/config.json';
import datefns from '@/modules/date';
import cache from '@/modules/cache';
import { auth, api, http } from '@/modules/auth';
import store from '@/modules/store';
import util from '@/modules/util';
import router from '@/router';
import App from '@/App.vue';
import '@/brand.scss';

Vue.config.productionTip = false;

Vue.prototype.$config = config;
Vue.prototype.$cache = cache;
Vue.prototype.$http = http;
Vue.prototype.$api = api;
Vue.prototype.$auth = auth;
Vue.prototype.$datefns = datefns;
Vue.prototype.$util = util;

Vue.config.errorHandler = (err, vm, info) => {
  if (err.type) {
    api.post('/error', err);
  } else {
    api.post('/error', { type: 'crash', error: `${vm.$el.baseURI} - ${info} - ${err.toString()}` });
  }
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
