<template>
  <div id="app">
    <ErrorPage v-if="$store.getters.error" :status="($store.getters.error).status" :title="($store.getters.error).title" :message="($store.getters.error).message" :icon="($store.getters.error).icon" />
    <router-view v-else :key="$route.fullPath" />
  </div>
</template>

<script>
import ErrorPage from '@/views/Error.vue';

export default {
  name: 'app',
  async mounted() {
    this.$auth.config(this);
    this.$cache.config(this);
  },
  components: {
    ErrorPage,
  },
};
</script>
