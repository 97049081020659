<template>
  <div class="sidebar-horizontal">
    <nav class="navbar bg-blue-gradient">
      <span v-if="previousPath !== undefined" class="d-inline-block" style="min-width: 64px;"></span>
      <router-link to="/" class="navbar-brand mx-auto"><p>{{ $config.name }}</p></router-link>
      <ul v-if="previousPath !== undefined" class="navbar-nav">
        <li class="nav-item mx-0"><button type="button" class="fs-48 text-white btn btn-link px-3" @click="close">&times;</button></li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    previousPath: String,
  },
  methods: {
    close() {
      this.$router.push(this.previousPath);
    },
  },
};
</script>
