<template>
  <div class="widget-holder">
    <div class="widget-bg">
      <div class="widget-body text-center py-5">
        <h4 class="mt-2 mb-0"><i class="fal fa-5x text-stroke-5 text-icon-gray" :class="iconClass"></i></h4>
        <h1>{{ status }} {{ title }}</h1>
        <p class="mt-4 fs-18 fw-300 heading-font-family">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: Number,
    icon: String,
    title: String,
    message: String,
  },
  computed: {
    iconClass() {
      if (this.icon === undefined) {
        return 'fa-exclamation-triangle';
      }
      return this.icon;
    },
  },
};
</script>
