import Axios from 'axios';

const http = Axios.create();
const api = Axios.create({ baseURL: process.env.NODE_ENV === 'production' ? '' : 'http://localhost:8000/1.0' });
api.defaults.headers.common.Accept = 'applicaton/json';
api.defaults.withCredentials = true;

let vm;

const auth = {
  config(t) {
    vm = t;
  },
  profile: {
    init: false,
  },
  async isAuthenticated() {
    if (!this.profile.init) {
      try {
        await this.loadProfile();
      } catch {
        // ignore any errors
      }
    }
    return this.profile.id !== undefined;
  },
  logIn(username, password, code) {
    return new Promise((resolve, reject) => {
      api.post('/login', { username, password, code })
        .then(() => {
          this.loadProfile()
            .then(() => {
              resolve(null);
            })
            .catch((e) => {
              reject(e);
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  loadProfile() {
    return new Promise((resolve, reject) => {
      api.get('/profile')
        .then((res) => {
          // save profile
          auth.profile = res.data;
          auth.profile.init = true;
          resolve(null);
        })
        .catch((e) => {
          this.clearProfile();
          reject(e);
        });
    });
  },
  clearProfile() {
    this.profile = { init: true };
    if (vm) {
      vm.$cache.flush();
    }
  },
  logOut() {
    return new Promise((resolve, reject) => {
      this.clearProfile();
      api.post('/logout')
        .then(() => {
          resolve(null);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

api.interceptors.response.use((response) => response, (e) => {
  if (e.response.status === 401 && e.response.config.url !== '/login' && e.response.config.url !== '/profile') {
    auth.clearProfile();
    // update error message
    e.response.data.message = 'Authorization failed';
    // auth is no longer valid
    setTimeout(() => { vm.$router.push({ path: '/login', query: { redirect: vm.$route.fullPath } }); }, 3500);
  }
  return Promise.reject(e);
});

export { auth, api, http };
