<template>
  <div>
    <Header />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">
          <AlertCard :icon="icon" :status="status" :title="defaultTitle" :message="defaultMessage" class="mt-3" />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import AlertCard from '@/components/AlertCard.vue';

export default {
  props: {
    status: Number,
    icon: String,
    title: String,
    message: String,
  },
  computed: {
    defaultTitle() {
      if (this.title === undefined) {
        switch (this.status) {
          case 404:
            return 'Page Not Found';
          default:
            return '';
        }
      }
      return this.title;
    },
    defaultMessage() {
      if (this.message === undefined) {
        switch (this.status) {
          case 404:
            return 'We\'re sorry. The web address you entered is not a functioning page on our site.';
          default:
            return '';
        }
      }
      return this.message;
    },
  },
  async mounted() {
    if (this.defaultTitle !== '') {
      document.title = `${this.defaultTitle} - ${this.$config.pretty_name}`;
    }
  },
  components: {
    Header,
    AlertCard,
  },
};
</script>
