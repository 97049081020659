import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    r: false,
    e: undefined,
  },
  mutations: {
    ready(state, r) {
      state.r = r;
      if (r) {
        document.dispatchEvent(new Event('render-done'));
      }
    },
    error(state, e) {
      if (e === false) {
        state.e = undefined;
      } else if (typeof e === 'number') {
        state.e = { status: e };
      } else if (e.message && typeof e.message === 'string' && e.message.length === 3 && typeof +e.message === 'number') {
        state.e = { status: +e.message };
      } else if (e.response) {
        state.e = { status: e.response.status, title: e.response.statusText, message: e.response.data.message };
      } else {
        state.e = e;
        throw ({ type: 'caught', error: e.toString() }); // eslint-disable-line
      }
    },
  },
  getters: {
    ready: (state) => state.r,
    error: (state) => state.e,
  },
});

export default store;
