import Vue from 'vue';
import VueRouter from 'vue-router';
import config from '@/config.json';

Vue.use(VueRouter);

let routes = [];
if (config.name === 'agiler') {
  routes = [
    { path: '/', meta: { title: 'Ecommerce Essentials & Optimization', tags: [{ name: 'description', content: 'Agiler provides the technical foundation needed for growth oriented ecommerce stores. Ecommerce Essentials + Conversion Rate Optimization (CRO) and LTV optimization.' }] }, component: () => import(/* webpackChunkName: "index" */ '@/views/Index.vue') },
    { path: '/index', meta: { title: 'Ecommerce Essentials & Optimization', tags: [{ name: 'description', content: 'Agiler provides the technical foundation needed for growth oriented ecommerce stores. Ecommerce Essentials + Conversion Rate Optimization (CRO) and LTV optimization.' }, { rel: 'canonical', href: '/' }] }, component: () => import(/* webpackChunkName: "index" */ '@/views/Index.vue') },
    { path: '/contact', meta: { title: 'Contact Client Representative', tags: [{ name: 'description', content: 'Talk directly with the e-commerce experts.' }] }, component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue') },
    { path: '/:section(help)/:article', component: () => import(/* webpackChunkName: "help-article" */ '@/views/HelpArticle.vue') },
    { path: '/:section(blog)', component: () => import(/* webpackChunkName: "blog-index" */ '@/views/BlogIndex.vue') },
    { path: '/:section(blog)/tag/:tag', component: () => import(/* webpackChunkName: "blog-index" */ '@/views/BlogIndex.vue') },
    { path: '/:section(blog)/:article', component: () => import(/* webpackChunkName: "blog-article" */ '@/views/BlogArticle.vue') },
  ];
} else {
  routes = [
    { path: '/', meta: { title: 'Log In', tags: [{ name: 'description', content: 'Log in to your account' }, { rel: 'canonical', href: '/login' }], authRedirect: '/dashboard' }, component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue') },
    { path: '/index', meta: { title: 'Log In', tags: [{ name: 'description', content: 'Log in to your account' }, { rel: 'canonical', href: '/login' }], authRedirect: '/dashboard' }, component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue') },
  ];
}
routes.push(...[
  { path: '/signup', meta: { title: 'Sign Up', tags: [{ name: 'description', content: 'Create a new account' }, { rel: 'canonical', href: '/signup' }], authRedirect: '/login' }, component: () => import(/* webpackChunkName: "signup" */ '@/views/Signup.vue') }, // authRedirect to login to check for update_billing flag in router beforeEach()
  { path: '/login', meta: { title: 'Log In', tags: [{ name: 'description', content: 'Log in to your account' }], authRedirect: '/dashboard' }, component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue') },
  { path: '/logout', meta: { title: 'Log Out' }, component: () => import(/* webpackChunkName: "logout" */ '@/views/Logout.vue') },

  { path: '/profile', meta: { title: 'User Settings', requiresAuth: true }, component: () => import(/* webpackChunkName: "profile-edit" */ '@/views/profile/Edit.vue') },
  { path: '/profile/2fa', meta: { title: 'Two-Factor Authentication - User Settings', requiresAuth: true }, component: () => import(/* webpackChunkName: "profile-twofactorauth" */ '@/views/profile/TwoFactorAuth.vue') },
  { path: '/profile/password/edit', meta: { title: 'Password - User Settings', requiresAuth: true }, component: () => import(/* webpackChunkName: "profile-password-edit" */ '@/views/profile/password/Edit.vue') },
  { path: '/profile/password/reset', meta: { title: 'Reset Password' }, component: () => import(/* webpackChunkName: "profile-password-reset" */ '@/views/profile/password/Reset.vue') },
  { path: '/profile/issues', meta: { title: 'Issue Activity - User Settings', requiresAuth: true }, component: () => import(/* webpackChunkName: "profile-issues" */ '@/views/profile/Issues.vue') },
  { path: '/profile/billing', meta: { title: 'Billing & Payments - User Settings', requiresAuth: true }, component: () => import(/* webpackChunkName: "profile-billing" */ '@/views/profile/Billing.vue') },
  { path: '/profile/billing/update', meta: { title: 'Payment Method', requiresAuth: true }, component: () => import(/* webpackChunkName: "profile-billing-update" */ '@/views/profile/BillingUpdate.vue') },
  { path: '/profile/verify', meta: { title: 'Email Verification' }, component: () => import(/* webpackChunkName: "profile-verify" */ '@/views/profile/Verify.vue') },
  { path: '/profile/*', props: { status: 404 }, component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue') },

  { path: '/admin/dashboard', meta: { requiresAuth: true }, component: () => import(/* webpackChunkName: "admin-dashboard" */ '@/views/admin/Dashboard.vue') },
  { path: '/admin/accounting', meta: { requiresAuth: true }, component: () => import(/* webpackChunkName: "admin-accounting" */ '@/views/admin/Accounting.vue') },
  { path: '/admin/accounting/ledger/:accountid/:year?', meta: { requiresAuth: true }, component: () => import(/* webpackChunkName: "admin-accounting-ledger" */ '@/views/admin/AccountingLedger.vue') },
  { path: '/admin/accounting/reports/income/:year?', meta: { requiresAuth: true }, component: () => import(/* webpackChunkName: "admin-accounting-report-income" */ '@/views/admin/AccountingReportIncome.vue') },
  { path: '/admin/*', props: { status: 404 }, component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue') },

  { path: '/dashboard', meta: { title: 'Projects', requiresAuth: true }, component: () => import(/* webpackChunkName: "dashboard-projects" */ '@/views/dashboard/Projects.vue') },

  { path: '/groups/new', meta: { title: 'New Group', requiresAuth: true }, component: () => import(/* webpackChunkName: "groups-edit" */ '@/views/groups/Edit.vue') },
  { path: '/:group/edit', meta: { title: 'Settings', requiresAuth: true }, component: () => import(/* webpackChunkName: "groups-edit" */ '@/views/groups/Edit.vue') },

  { path: '/projects/new', meta: { title: 'New Project', requiresAuth: true }, component: () => import(/* webpackChunkName: "projects-edit" */ '@/views/projects/Edit.vue') },
  { path: '/:group/:project/edit', meta: { title: 'Settings', requiresAuth: true }, component: () => import(/* webpackChunkName: "projects-edit" */ '@/views/projects/Edit.vue') },

  { path: '/:group/:project', meta: { title: '', requiresAuth: true }, component: () => import(/* webpackChunkName: "projects-overview" */ '@/views/projects/Overview.vue') },
  { path: '/:group/:project/issues', meta: { title: 'Issues', requiresAuth: true }, component: () => import(/* webpackChunkName: "projects-issues" */ '@/views/projects/Issues.vue') },
  { path: '/:group/:project/issues/new', meta: { title: 'Issues', requiresAuth: true }, component: () => import(/* webpackChunkName: "projects-issues-new" */ '@/views/projects/IssuesNew.vue') },
  { path: '/:group/:project/issues/:number', meta: { title: 'Issue', requiresAuth: true }, component: () => import(/* webpackChunkName: "projects-issues-details" */ '@/views/projects/IssueDetails.vue') },
  { path: '/:group/:project/labels', meta: { title: 'Labels', requiresAuth: true }, component: () => import(/* webpackChunkName: "projects-labels" */ '@/views/projects/Labels.vue') },
  { path: '/:group/:project/files/:directory*', meta: { title: 'Files', requiresAuth: true }, component: () => import(/* webpackChunkName: "projects-files" */ '@/views/projects/Files.vue') },
  { path: '/:group/:project/credentials', meta: { title: 'Credentials', requiresAuth: true }, component: () => import(/* webpackChunkName: "projects-credentials" */ '@/views/projects/Credentials.vue') },
  { path: '/:group/:project/members', meta: { title: 'Team', requiresAuth: true }, component: () => import(/* webpackChunkName: "projects-members" */ '@/views/projects/Members.vue') },
  { path: '/:group', component: () => import(/* webpackChunkName: "dashboard-projects" */ '@/views/dashboard/Projects.vue') },

  { path: '*', props: { status: 404 }, component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue') },
]);

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  }
  if (to.hash) {
    if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
      return { selector: to.hash };
    }
  }
  return { x: 0, y: 0 };
};

const router = new VueRouter({
  mode: 'history',
  scrollBehavior,
  routes,
});

router.beforeEach(async (to, from, next) => {
  router.app.$store.commit('ready', false);

  // set title
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.meta.title} - ${router.app.$config.pretty_name}`;
  }

  // remove old meta tags
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));
  // set meta tags
  const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.tags);
  if (nearestWithMeta) {
    nearestWithMeta.meta.tags.map((tagDef) => router.app.$util.createMetaTag(tagDef.rel && tagDef.rel === 'canonical' ? 'link' : 'meta', tagDef));
  }

  // auth
  if (to.matched.some((record) => record.meta.requiresAuth) && !await router.app.$auth.isAuthenticated()) {
    next({ path: '/login', query: { redirect: to.fullPath } });
  } else if (to.matched.some((record) => record.meta.authRedirect) && await router.app.$auth.isAuthenticated()) {
    if (to.fullPath === '/login') {
      if (router.app.$auth.profile.update_billing) {
        next('/profile/billing/update');
      } else if (router.app.$auth.profile.verify_email) {
        next('/profile/verify');
      } else {
        next(to.meta.authRedirect);
      }
    } else {
      next(to.meta.authRedirect);
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  router.app.$store.commit('error', false);
});

export default router;
